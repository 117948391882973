import React from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import Button from './Button'
import { Layout as _Layout, Color, Text } from '../constants'
import Icon from './Icons'

export const Pricing = ({ data, background }) => {
  const staticData = {
    title: 'Prices & Bundles',
    header: 'All-Inclusive Packages Include:',
    subHeader: 'Hours your professional organizer spends in your space, plus:',
    allInclusiveItems: [
      {
        text: '30-60 minute in-home consultation to discuss your project',
        icon: 'calendar',
      },

      {
        text: 'Designing / planning layouts and organizing solutions',
        icon: 'todoList',
      },
      {
        text: 'Shopping for approved organizing products',
        icon: 'cart',
      },
      {
        text: 'Arranging appointments with other service providers as needed',
        icon: 'calendarTime',
      },

      {
        text:
          'Delivering items for consignment, donation or eco-friendly disposal',
        icon: 'car',
      },
      {
        text: 'Communication, support, and lots of encouragement! ',
        icon: 'heart',
      },
    ],
    bundles: [
      {
        title: 'Small Bundle',
        sessions: 2,
        hours: 6,
        price: 135,
        color: '#E39476',
      },
      {
        title: 'Medium Bundle',
        sessions: 4,
        hours: 12,
        price: 115,
        color: '#FA8875',
      },
      {
        title: 'Large Bundle',
        sessions: 6,
        hours: 18,
        price: 100,
        color: '#F07174',
      },
    ],
    singleSession: {
      session: 1,
      hours: 3,
      price: 150,
      total: 150,
    },
    customPackage:
      'Custom packages are also available and can be created during your free in-home consultation.',
    button: {
      type: 'cta',
      text: 'Book Now',
      action: 'contact',
    },
  }

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: background === 'light' ? Color.DEFAULT : Color.WHITE,
      padding: _Layout.EDGE_PADDING,
    },
    wrapper: {
      width: '100%',
      paddingRight: _Layout.EDGE_PADDING,
      paddingLeft: _Layout.EDGE_PADDING,
    },
    pageHeader: { ...Text.PAGE_HEADING, textAlign: 'center' },
    title: {
      ...Text.PAGE_HEADING,
      textAlign: 'center',
      paddingBottom: _Layout.EDGE_PADDING * 2,
    },
    span: {
      ...Text.SPAN,
      paddingBottom: _Layout.EDGE_PADDING * 2,
    },
    header: {
      ...Text.HEADER,
      textAlign: 'center',
      paddingTop: _Layout.EDGE_PADDING,
      paddingBottom: _Layout.EDGE_PADDING,
    },
    subHeader: { ...Text.SUBHEADER, paddingBottom: 12, textAlign: 'left' },
    inclusiveItemContainer: {
      maxWidth: 1100,
      margin: 'auto',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gridGap: 16,
    },
    listItem: {
      ...Text.BODY,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      padding: _Layout.EDGE_PADDING,
      minHeight: 160,
      marginBottom: _Layout.ELEMENT_PADDING,
    },
    inclusiveItemText: {
      maxWidth: 240,
      marginTop: _Layout.ELEMENT_PADDING,
      ...Text.BODY,
      fontWeight: '300',
    },
    label: { ...Text.LABEL, padding: _Layout.EDGE_PADDING },
    body: { ...Text.BODY },
    ready: {
      ...Text.SUBHEADER,
      paddingTop: _Layout.EDGE_PADDING,
      paddingBottom: _Layout.EDGE_PADDING * 2,
    },
    bundles: {
      '@media (max-width: 1150px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
      display: 'flex',
      flexDirection: 'row',
      marginTop: _Layout.OUTER_PADDING * 4,
      marginBottom: _Layout.OUTER_PADDING * 4,
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: 320,
      height: 240,
      borderRadius: 4,
      margin: _Layout.OUTER_PADDING,
    },
  }
  const singleSession = `${get(
    staticData,
    'singleSession.hours'
  )} hours for $${get(staticData, 'singleSession.price')}`

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.wrapper)}>
        <h2 className={css(styles.title)}>{staticData.title}</h2>
        <div className={css(styles.header)}>{staticData.header}</div>
        <div>
          <div
            className={css(styles.subHeader, {
              textAlign: 'center',
              marginBottom: _Layout.EDGE_PADDING * 3,
            })}
          >
            {staticData.subHeader}
          </div>
          <ul className={css(styles.inclusiveItemContainer)}>
            {staticData.allInclusiveItems &&
              staticData.allInclusiveItems.map((item, i) => (
                <li key={`${item.text}-${i}`} className={css(styles.listItem)}>
                  <Icon type={item.icon} size={48} fill="white" />
                  <p className={css(styles.inclusiveItemText)}>{item.text}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ul className={css(styles.bundles)}>
        {staticData.bundles.map((item, i) => (
          <li
            key={`${item.title}-${i}`}
            className={css([styles.card, { background: item.color }])}
          >
            <div className={css({ ...Text.TITLE, textAlign: 'center' })}>
              {item.title}
            </div>
            <div className={css({ ...Text.HEADER, textAlign: 'center' })}>
              {item.sessions} Sessions
            </div>
            <div className={css(styles.span)}>
              {item.hours} Hours of In-Home Organization
            </div>
            <div>
              ${item.price * item.sessions} (save $
              {staticData.singleSession.price * item.sessions -
                item.price * item.sessions}
              )
            </div>
            {/* <div className={css(styles.label)}>
              rate per session is ${item.price}
            </div> */}
          </li>
        ))}
      </ul>
      <div className={css(styles.subHeader)}>Looking for a single session?</div>
      <div className={css(styles.subHeader)}>{singleSession}</div>
      <span className={css(styles.label)}>{staticData.customPackage}</span>
      <div className={css(styles.ready)}>Ready to get started?</div>
      <a href={get(staticData, 'button.action')}>
        <Button
          btnType={get(staticData, 'button.type')}
          btnText={get(staticData, 'button.text')}
        />
      </a>
    </div>
  )
}

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default Pricing
