import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from 'emotion'
import { get } from 'lodash'
import { Color, Layout as _Layout, Text } from '../constants'
import Layout from '../components/Layout'
import { ServicesIntro } from '../components/ServicesIntro'
import { CurveSection } from '../components/CurveSection'
import { Pricing } from '../components/Pricing'

import Testimonials from '../components/Testimonials'
import Hero from '../components/Hero'

export class ServicePageTemplate extends Component {
  render() {
    const styles = {}
    const { hero, servicesIntro } = this.props
    return (
      <div>
        <Hero {...hero} />
        <div>
          <ServicesIntro {...servicesIntro} background="light" />
          <CurveSection
            padding={6}
            render={() => <Pricing background="dark" />}
          />
        </div>
      </div>
    )
  }
}

ServicePageTemplate.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    button: PropTypes.string,
  }),
  testimonials: PropTypes.array,
}

const ServicePage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    navbarData,
    footerData,
    servicesIntroData,
  } = data
  const servicesData = get(servicesIntroData, 'edges[0].node.frontmatter')

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <ServicePageTemplate
        hero={frontmatter.hero}
        servicesIntro={servicesData}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicePage

export const productPageQuery = graphql`
  query ServicePage($id: String!) {
    ...LayoutFragment
    ...ServicesIntroFragment
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          type
          button
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`
