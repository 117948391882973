import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'emotion'
import { v4 } from 'uuid'
import { Color } from '../constants'

const styles = {
  message: {
    backgroundColor: Color.WHITE_BLUE,
    borderRadius: 4,
    fontSize: '1rem',
    marginBottom: '1.5rem'
  },
  messageBody: {
    borderColor: Color.LIGHT_GRAY,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: '0 0 0 4px',
    color: '#4a4a4a',
    padding: '1.25em 1.5em'
  }
}
const Testimonials = ({ testimonials }) => (
  <div>
    {testimonials.map(testimonial => (
      <article key={v4()} className={css(styles.message)}>
        <div className={css(styles.messageBody)}>
          {testimonial.quote}
          <br />
          <cite> – {testimonial.author}</cite>
        </div>
      </article>
    ))}
  </div>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
}

export default Testimonials
